/* eslint-disable import/prefer-default-export */
import React from 'react';
import { CacheProvider } from '@emotion/react';
import getEmotionCache from './getEmotionCache';
import {
  SettingsConsumer,
  SettingsProvider,
} from "../../src/contexts/settings-context";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme } from "../../src/theme";
import { SnackbarProvider } from "notistack";


const cache = getEmotionCache();


function TopLayout(props) {
  return (
    <>
      <CacheProvider value={cache}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider
                    theme={createTheme({
                      direction: settings.direction,
                      responsiveFontSizes: settings.responsiveFontSizes,
                      theme: settings.theme,
                      lang: settings.lang,
                    })}
                  >
                    <SnackbarProvider maxSnack={3}>
                        <CssBaseline />
                        {props.children}
                    </SnackbarProvider>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </LocalizationProvider>
      </CacheProvider>
    </>
  );
}

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
